
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="pageType==='list'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('list')">招商列表</div>
          <div v-if="pageType==='detail'" :class="pageType==='detail'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('detail')">招商详情</div>
          <div v-if="pageType==='create'" :class="pageType==='create'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('create')">招商编辑</div>
        </div>

        <component :is="pageType + 'Component'" ref="bottom"  />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['@/components/investment/List'], resolve)
    },
    detailComponent: (resolve) => {
      require(['@/components/investment/modalComponents/detail'], resolve)
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_investment_pageType', val)
    },
    reloadList () {
      this.$store.commit('set_investment_pageType', 'list')
      this.$store.commit('set_investment_update', new Date())
    },
    handleCreate () {
      this.$store.commit('set_investment_pageType', 'create')
    },
    showinvestDetail (id) {
      this.$store.commit('set_investment_investmentId', id)
      this.$store.commit('set_investment_pageType', 'detail')
    }

  },
  computed: {
    pageType () {
      return this.$store.state.investmentManage.pageType
    }
  },
  watch: {
  }
}
</script>
